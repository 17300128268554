import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { environment } from '../../../environments/environment';
import { AppStore } from '../../app.store';
import { WebsocketEvent } from '@webcoffee/interfaces';
import { NotificationsStore } from '../../layouts/default-layout/data-access/notifications.store';

export enum SocketType {
    SERVER = 'SERVER',
    ADMIN = 'ADMIN',
}

@Injectable({ providedIn: 'root' })
export class SocketClientService {
    socket: Socket;

    constructor(
        private appStore: AppStore,
        private notificationsStore: NotificationsStore,
    ) {}

    public disconnect() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }
    public connect() {
        if (this.socket) {
            this.socket.connect();
        } else {
            this.socket = io(environment.WS_ADMIN, {
                transports: ['websocket'],
                withCredentials: true,
                closeOnBeforeunload: true,
            });
            this.initWSListeners();
        }
    }

    initWSListeners() {
        this.socket.on('connect', () => {
            this.appStore.patchState({ errorCode: null });
        });

        this.socket.on('disconnect', (reason) => {
            if (reason === 'transport close') this.appStore.patchState({ errorCode: 500 });
        });

        this.socket.on('connect_error', (err) => {});

        this.socket.on(WebsocketEvent.UNREAD_NOTIFICATIONS, (count) => {
            this.notificationsStore.patchState({ unreadNotifications: count });
        });
        this.socket.on(WebsocketEvent.NOTIFICATION, (notification) => {
            this.notificationsStore.updateUsersNotifications({
                notification,
                read: false,
            });
            this.notificationsStore.increamentUnreadNotifications();
            // TODO this.tostr. mesaj custom: User a facut actiunea bla.
        });
        this.socket.on(WebsocketEvent.PROVIDERS, (notification) => {
            console.log(notification);
        });
    }
}
